import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const List = props => {
  const { edges, theme } = props

  return (
    <React.Fragment>
      <ul>
        {edges.map(edge => {
          const {
            node: {
              frontmatter: { title },
              fields: { slug },
            },
          } = edge

          return (
            <li key={slug}>
              <Link to={slug}>{title}</Link>
            </li>
          )
        })}
      </ul>

      {/* --- STYLES --- */}
    </React.Fragment>
  )
}

List.propTypes = {
  edges: PropTypes.array.isRequired,
  theme: PropTypes.object.isRequired,
}

export default List
