import PropTypes from 'prop-types'
import React from 'react'

import Item from './Item'

const Blog = props => {
  const { posts, theme } = props

  return (
    <React.Fragment>
      {posts.map(post => {
        const {
          node,
          node: {
            fields: { slug },
          },
        } = post
        return (
          <div key={slug} className="posts-list__item">
            <Item post={node} theme={theme} />
          </div>
        )
      })}
    </React.Fragment>
  )
}

Blog.propTypes = {
  posts: PropTypes.array.isRequired,
  theme: PropTypes.object.isRequired,
}

export default Blog
