import React from 'react'
import PropTypes from 'prop-types'

const Article = props => {
  const { children, theme } = props

  return (
    <React.Fragment>
      <article className="article">{children}</article>

      {/* --- STYLES --- */}
    </React.Fragment>
  )
}

Article.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.object.isRequired,
}

export default Article
