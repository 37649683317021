// react
import React from 'react'

// third-party
import PropTypes from 'prop-types'
import { useStaticQuery, graphql, Link } from 'gatsby'

// application
// import Collapse from '../shared/Collapse';
import ArrowRoundedRight6x9Svg from '!svg-react-loader!../../images/svg/arrow-rounded-right-6x9.svg'

function WidgetCategories(props) {
  const data = useStaticQuery(
    graphql`
      query UniqueCategories {
        allMarkdownRemark(filter: { fields: { source: { eq: "posts" } } }) {
          distinct(field: frontmatter___category)
        }
      }
    `
  )

  const categoriesList = data.allMarkdownRemark.distinct.map(category => {
    return (
      <li className="widget-categories__item">
        <div className="widget-categories__row">
          <Link to={`/category/${category.toLowerCase().replace(' ', '-')}`}>
            <ArrowRoundedRight6x9Svg className="widget-categories__arrow" />
            {category}
          </Link>
        </div>
      </li>
    )
  })

  return (
    <div
      className={`widget-categories widget-categories--location--blog widget`}
    >
      <h4 className="widget__title">Categories</h4>
      <ul className="widget-categories__list">{categoriesList}</ul>
    </div>
  )
}

WidgetCategories.propTypes = {
  /**
   * category array
   */
  categories: PropTypes.array,
  /**
   * widget location (default: 'blog')
   * one of ['blog', 'shop']
   */
  location: PropTypes.oneOf(['blog', 'shop']),
}

WidgetCategories.defaultProps = {
  categories: [],
  location: 'blog',
}

export default WidgetCategories
