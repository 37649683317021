// react
import React from 'react'

// third-party
import PropTypes from 'prop-types'

// widgets
import WidgetAboutus from '../Widgets/WidgetAboutus'
import WidgetCategories from '../Widgets/WidgetCategories'
// import WidgetComments from '../Widgets/WidgetComments';
// import WidgetNewsletter from '../Widgets/WidgetNewsletter';
// import WidgetPosts from '../Widgets/WidgetPosts';
// import WidgetTags from '../Widgets/WidgetTags';

export default function BlogSidebar(props) {
  const { position } = props

  return (
    <div className={`block block-sidebar block-sidebar--position--${position}`}>
      <div className="block-sidebar__item">
        <WidgetAboutus />
      </div>
      <div className="block-sidebar__item">
        <WidgetCategories />
      </div>
      {/*<div className="block-sidebar__item">
                <WidgetPosts posts={posts.slice(0, 3)} />
            </div>
            <div className="block-sidebar__item">
                <WidgetTags />
            </div>*/}
    </div>
  )
}

BlogSidebar.propTypes = {
  /**
   * sidebar position (default: 'start')
   * one of ['start', 'end']
   * for LTR scripts "start" is "left" and "end" is "right"
   */
  position: PropTypes.oneOf(['start', 'end']),
}

BlogSidebar.defaultProps = {
  position: 'end',
}
