import { FaTag } from 'react-icons/fa/'
import PropTypes from 'prop-types'
import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import StoreContext, { defaultStoreContext } from '../context/StoreContext'
// import { ThemeContext } from "../layouts";
import Article from '../components/Article'
import Headline from '../components/Article/Headline'
import List from '../components/List'
import PageHeader from '../components/Shared/PageHeader'
import Blog from '../components/Blog'
import BlogSidebar from '../components/Blog/BlogSidebar'

const CategoryTemplate = props => {
  const {
    pageContext: { category },
    data: {
      allMarkdownRemark: { totalCount, edges },
      site: {
        siteMetadata: { facebook },
      },
    },
  } = props

  const breadcrumb = [
    { title: 'Home', url: '/' },
    { title: 'Blog', url: '/blog' },
    {
      title: category,
      url: `/category/${category.toLowerCase().replace(' ', '-')}`,
    },
  ]

  return (
    <React.Fragment>
      <PageHeader header={category} breadcrumb={breadcrumb} />

      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-8">
            <div className="block">
              <div className="posts-view">
                <div className="posts-view__list posts-list posts-list--layout--classic">
                  <div className="posts-list__body">
                    {/*<p className="meta">
                      There {totalCount > 1 ? "are" : "is"} <strong>{totalCount}</strong> post{totalCount >
                      1
                        ? "s"
                        : ""}{" "}
                        in <strong>{category}</strong>.
                      </p>*/}
                    <StoreContext.Consumer>
                      {theme => <Blog posts={edges} theme={theme} />}
                    </StoreContext.Consumer>
                  </div>
                </div>
                {/*<div className="posts-view__pagination">
                    <Pagination
                        current={page}
                        siblings={2}
                        total={10}
                        onPageChange={this.handlePageChange}
                    />
                </div>*/}
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <BlogSidebar position="end" />
          </div>
        </div>
      </div>

      <SEO title={category} />
    </React.Fragment>
  )
}

CategoryTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default CategoryTemplate

// eslint-disable-next-line no-undef
export const categoryQuery = graphql`
  query PostsByCategory($category: String) {
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [fields___prefix], order: DESC }
      filter: {
        frontmatter: { category: { eq: $category } }
        fileAbsolutePath: { regex: "//posts/[0-9]+.*--/" }
      }
    ) {
      totalCount
      edges {
        node {
          excerpt
          fields {
            slug
            prefix
          }
          frontmatter {
            title
            category
            author
            cover {
              children {
                ... on ImageSharp {
                  fluid(maxWidth: 800, maxHeight: 360) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        facebook {
          appId
        }
      }
    }
  }
`
