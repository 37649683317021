// import { FaCalendar } from "react-icons/fa/";
// import { FaTag } from "react-icons/fa/";
// import { FaUser } from "react-icons/fa/";
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

const Item = props => {
  const {
    theme,
    post: {
      excerpt,
      fields: { slug, prefix },
      frontmatter: {
        title,
        category,
        author,
        cover: {
          children: [{ fluid }],
        },
      },
    },
  } = props

  // grid-lg

  return (
    <React.Fragment>
      <div className="post-card post-card--layout--grid post-card--size--lg">
        <div className="post-card__image">
          <Link to={slug} key={slug}>
            <Img fluid={fluid} />
          </Link>
        </div>
        <div className="post-card__info">
          <div className="post-card__category">
            <Link to={`/category/${category.toLowerCase().replace(' ', '-')}`}>
              {category}
            </Link>
          </div>
          <div className="post-card__name">
            <Link to={slug} key={slug}>
              {title}
            </Link>
          </div>
          <div className="post-card__date">{prefix}</div>
          <div className="post-card__content">{excerpt}</div>
          <div className="post-card__read-more">
            <Link to={slug} className="btn btn-secondary btn-sm">
              Read More
            </Link>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

Item.propTypes = {
  post: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}

export default Item
