// react
import React from 'react'
import { FaFacebookF } from 'react-icons/fa/'
import { FaInstagram } from 'react-icons/fa/'
import { FaTwitter } from 'react-icons/fa/'
import { FaRss } from 'react-icons/fa/'

function WidgetAboutus() {
  // const links = [
  //     {
  //         key: 'facebook',
  //         url: 'https://www.facebook.com/rawdogfoodco/',
  //         iconClass: 'fab fa-facebook-f',
  //     },
  //     {
  //         key: 'instagram',
  //         url: 'https://www.instagram.com/rawdogfoodco',
  //         iconClass: 'fab fa-instagram',
  //     },
  //     {
  //         key: 'twitter',
  //         url: 'https://twitter.com/rawdogfoodco',
  //         iconClass: 'fab fa-twitter',
  //     },        {
  //         key: 'rss',
  //         url: '/rss.xml',
  //         iconClass: 'fas fa-rss',
  //     }
  // ].map((item) => {
  //     const itemClasses = `widget-aboutus__link widget-aboutus__link--${item.key}`;
  //     const iconClasses = `widget-social__icon ${item.iconClass}`;

  //     return (
  //         <li key={item.key}>
  //             <a className={itemClasses} href="/" target=" _blank">
  //                 <i className={iconClasses} />
  //             </a>
  //         </li>
  //     );
  // });

  return (
    <div className="widget-aboutus widget">
      <h4 className="widget__title">About Blog</h4>
      <div className="widget-aboutus__text">
        Welcome to the blog where we explore raw feeding to help our dogs live
        forever.
      </div>
      <div className="widget-aboutus__socials">
        <ul>
          <li key="facebook">
            <a
              className="widget-aboutus__link widget-aboutus__link--facebook"
              href="https://www.facebook.com/rawdogfoodco/"
              target=" _blank"
              rel="noopener noreferrer"
            >
              <FaFacebookF />
            </a>
          </li>
          <li key="instagram">
            <a
              className="widget-aboutus__link widget-aboutus__link--instagram"
              href="https://www.instagram.com/rawdogfoodco"
              target=" _blank"
              rel="noopener noreferrer"
            >
              <FaInstagram />
            </a>
          </li>
          <li key="twitter">
            <a
              className="widget-aboutus__link widget-aboutus__link--twitter"
              href="https://twitter.com/rawdogfoodco"
              target=" _blank"
              rel="noopener noreferrer"
            >
              <FaTwitter />
            </a>
          </li>
          <li key="rss">
            <a
              className="widget-aboutus__link widget-aboutus__link--rss"
              href="/rss.xml"
              target=" _blank"
              rel="noopener noreferrer"
            >
              <FaRss />
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default WidgetAboutus
